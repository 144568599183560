export default [
    {code: "100", desc: "Continue", ext: "png"},
    {code: "101", desc: "Switching Protocols", ext: "png"},
    {code: "102", desc: "Processing", ext: "png"},
    {code: "200", desc: "OK", ext: "png"},
    {code: "201", desc: "Created", ext: "png"},
    {code: "202", desc: "Accepted", ext: "png"},
    {code: "203", desc: "Non-Authoritative Information", ext: "png"},
    {code: "204", desc: "No Content", ext: "png"},
    {code: "206", desc: "Partial Content", ext: "png"},
    {code: "207", desc: "Multi-Status", ext: "png"},
    {code: "300", desc: "Multiple Choices", ext: "png"},
    {code: "301", desc: "Moved Permanently", ext: "png"},
    {code: "302", desc: "Found", ext: "png"},
    {code: "303", desc: "See Other", ext: "png"},
    {code: "304", desc: "Not Modified", ext: "png"},
    {code: "305", desc: "Use Proxy", ext: "png"},
    {code: "307", desc: "Temporary Redirect", ext: "gif"},
    {code: "308", desc: "Permanent Redirect", ext: "gif"},
    {code: "400", desc: "Bad Request", ext: "png"},
    {code: "401", desc: "Unauthorized", ext: "png"},
    {code: "402", desc: "Payment Required", ext: "png"},
    {code: "403", desc: "Forbidden", ext: "png"},
    {code: "404", desc: "Not Found", ext: "png"},
    {code: "405", desc: "Method Not Allowed", ext: "png"},
    {code: "406", desc: "Not Acceptable", ext: "png"},
    {code: "407", desc: "Proxy Authentication Required", ext: "png"},
    {code: "408", desc: "Request Timeout", ext: "png"},
    {code: "409", desc: "Conflict", ext: "jpg"},
    {code: "410", desc: "Gone", ext: "png"},
    {code: "411", desc: "Length Required", ext: "png"},
    {code: "412", desc: "Precondition Failed", ext: "png"},
    {code: "413", desc: "Payload Too Large", ext: "png"},
    {code: "414", desc: "Request-URI Too Long", ext: "gif"},
    {code: "415", desc: "Unsupported Media Type", ext: "png"},
    {code: "416", desc: "Request Range Not Satisfiable", ext: "png"},
    {code: "417", desc: "Expectation Failed", ext: "png"},
    {code: "418", desc: "I’m a teapot", ext: "png"},
    {code: "420", desc: "Enhance Your Calm", ext: "gif"},
    {code: "421", desc: "Misdirected Request", ext: "gif"},
    {code: "422", desc: "Unprocessable Entity", ext: "gif"},
    {code: "423", desc: "Locked", ext: "jpg"},
    {code: "424", desc: "Failed Dependency", ext: "png"},
    {code: "425", desc: "Too Early", ext: "gif"},
    {code: "426", desc: "Upgrade Required", ext: "jpg"},
    {code: "429", desc: "Too Many Requests", ext: "png"},
    {code: "431", desc: "Request Header Fields Too Large", ext: "png"},
    {code: "444", desc: "No Response", ext: "jpg"},
    {code: "450", desc: "Blocked by Windows Parental Controls", ext: "jpg"},
    {code: "451", desc: "Unavailable For Legal Reasons", ext: "png"},
    {code: "497", desc: "HTTP Request Sent to HTTPS Port", ext: "png"},
    {code: "498", desc: "Token expired/invalid", ext: "jpg"},
    {code: "499", desc: "Client Closed Request", ext: "jpg"},
    {code: "500", desc: "Internal Server Error", ext: "gif"},
    {code: "501", desc: "Not Implemented", ext: "png"},
    {code: "502", desc: "Bad Gateway", ext: "png"},
    {code: "503", desc: "Service Unavailable", ext: "png"},
    {code: "504", desc: "Gateway Timeout", ext: "png"},
    {code: "506", desc: "Variant Also Negotiates", ext: "jpg"},
    {code: "507", desc: "Insufficient Storage", ext: "jpg"},
    {code: "508", desc: "Loop Detected", ext: "gif"},
    {code: "509", desc: "Bandwidth Limit Exceeded", ext: "jpg"},
    {code: "510", desc: "Not Extended", ext: "png"},
    {code: "511", desc: "Network Authentication Required", ext: "png"},
    {code: "521", desc: "Web Server Is Down", ext: "jpg"},
    {code: "523", desc: "Origin Is Unreachable", ext: "jpg"},
    {code: "525", desc: "SSL Handshake Failed", ext: "jpg"},
    {code: "599", desc: "Network Connect Timeout Error", ext: "gif"},
    {code: "999", desc: "Request Denied", ext: "png"},
];