<template>
  <div>
    <hr>
    <div style="text-align: center">
      <div>
        Site By: <a href="https://blog.zsh2517.com">zsh2517</a>
        GitHub: <a href="https://github.com/zsh2517/huajico.de">huajico.de</a>, 欢迎参与
      </div>
      <div><s>几个小时摸<span style="color: white; ">鱼</span>出来的页面，感谢群友提供表情包和灵感（x）</s></div>
      <div>状态码参考自
        <a href="https://http.cat">http.cat</a>&nbsp;<a href="https://http.dog">http.dog</a>
      </div>
      <div><s>点击左上角的滑稽试试？</s></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>